body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



* {
  box-sizing: border-box;
}


.intro-page {
  position: relative;
  max-width:100%;
  max-height: auto;
  top: 0px;
  background: #F5F7FB;
}

.intro-page-q{
  margin-left:20px;
  margin-right:20px;
}

.blob5 {
  position:fixed;
  top:0px;
  right: 0px;
}


.blobs {
  position:fixed;
  bottom:0px;
}

.quizzical{
position: relative;
height: 41px;
left: 2%;
top: 218px;
font-family: 'Karla';
font-style: normal;
font-weight: 700;
font-size: 31.25px;
line-height: 37px;
text-align: center;

color: #293264;
}

.description{
  position: relative;

  height: 19px;
  top: 190px;
  text-align: center;
  font-size: 20px;
}

.start-quiz-btn {
  position: relative;
  width: 193px;
  height: 52px;
  background: #4D5B9E;
  border-radius: 15px;
  color: white;

  top: 190px;
  text-align: center;
  left:25%;
}

.question-container {

}


.quiz-question {

  height: 35.74px;

  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;

  color: #293264;
}


.hr-line {
  border: 0.794239px solid #DBDEF0;
}

.radioBtn{
width: 65.92px;
height: 20.65px;
left: 75px;
top: 70.98px;

background: #D6DBF5;
border-radius: 7.94239px;
}

.options {
  list-style-type:none;
  display: grid;
  grid-auto-flow: column;
  align-self: center;
  grid-gap: 2px;
}

.options li {
   font-size: 13px;
  height: auto;
   
}

.options label, .options input {
  display:block;


  
}

.options input[type="radio"] {
  opacity:0.011;
  z-index:100;
  
  
}

.options input[type="radio"]:checked + label {
  background:#D6DBF5;
}

.options label {
   padding:10px;
   border:1px solid #CCC; 
   cursor:pointer;
  z-index:90;
  
  max-width: 100%;
  top: 170.46px;
  border-radius: 15.71045px;
}


.options label:hover {
   background:#DDD;
}


.submit-btn {
width: 193px;
height: 52px;
left: 179px;
top: 450px;
margin-top: 20px;

background: #4D5B9E;
border-radius: 15px;
color: white;
margin: auto;
}

.btn-container{
  display: grid;
}

.correct-answer {
   background:#94D7A2 !important;
   color: black !important;
}
.submitted{
  color: #8F94AF;
}

.incorrect-answer {
   background:#F6D9DB !important;
   color: #8F94AF;
}

.score-text {
height: 15px;

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 15px;
text-align: center;

color: #293264;
}

